import { useState, useEffect, useRef} from 'react'
import { getConfiguration } from './Config';

const App = () => {
  const [value, setValue] = useState(null)
  const [message, setMessage] = useState(null)
  const [previousChats, setPreviousChats] = useState([])
  const [currentTitle, setCurrentTitle] = useState(null)
  // Ref for the feed container
  const feedEndRef = useRef(null);
  const baseUrl = getConfiguration().apiUrl;

  const scrollToBottom = () => {
    feedEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [previousChats]); // Run this effect when previousChats changes

  const createNewChat = () => {
    setMessage(null)
    setValue("")
    setCurrentTitle(null)
  }

  const handleClick = (uniqueTitle) => {
    setCurrentTitle(uniqueTitle)
    setMessage(null)
    setValue("")
  }

  const handleKeyDown =  async (event) => {
    if (event.key === 'Enter') {
      await getMessages()
    }
  }

  

  const getMessages = async () => {
    
    const options = {
      method: "POST",
      body: JSON.stringify({
        message: value
      }),
      headers: {
        "Content-Type": "application/json"
      }
    }
    try {

      console.log(baseUrl)
      const response = await fetch(baseUrl+'/completions', options)
      const data = await response.json()
      setMessage(data.choices[0].message)
    } catch(error){
      console.error(error)
    }

  }

  useEffect(() => {
    console.log(currentTitle, value, message)
    if(!currentTitle && value && message){
      setCurrentTitle(value)
    }
    if(currentTitle && value && message) {
      setPreviousChats(prevChats => (
        [...prevChats,
          {
            title: currentTitle,
            role: "user",
            content: value
          },
          {
            title: currentTitle,
            role: message.role,
            content: message.content
          }
        ]
      ))

    }

  }, [message, currentTitle])


  const currentChat = previousChats.filter(previousChats => previousChats.title === currentTitle)
  const uniqueTiltes = Array.from(new Set(previousChats.map(previousChat => previousChat.title)))
 

  return (
    <div className="app">
      <section className="side-bar">
        <button onClick={createNewChat}>+ New chat</button>
        <ul className="history">
        {uniqueTiltes?.map((uniqueTilte, index) => <li key={index}>{uniqueTilte}</li>)}
        </ul>
        <nav>

          <p>Made by</p>
          <img src="images/logo_footer.png" alt="CAM-Service" width="150" />
        </nav>
      </section>
      <section className="main">
        {!currentTitle && <h1>CAGILA Assist</h1>}
        <ul className="feed">
          {currentChat?.map((chatMessage, index) => <li key={index}>
            <p className="role">{chatMessage.role}</p>
            <p>{chatMessage.content}</p>
          </li>)}
          <div ref={feedEndRef} />
        </ul>
        <div className="bottom-section">
          <div className="input-container">
            <input value={value} onKeyDown={handleKeyDown} onChange={(e) => setValue(e.target.value)} />
            <div id="submit" onClick={getMessages} >➢</div>
          </div>
          <p className="info">
            CAGILA Assist 0.1.1 Version. Free Research Preview.
            Our goal is to make AI system more natural and safe to interact with.
            Your feedback will help us improve
          </p>
        </div>
      </section>
    </div>
  )
}

export default App