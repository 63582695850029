const serverVars = {
    authUrl: '#{authUrl}#',
    apiUrl: 'https://happy-pebble-01961ba03.5.azurestaticapps.net:8000',
  };
  
  const localVars = {
    authUrl: 'local_auth_url',
    apiUrl: 'http://localhost:8000',
  
  };
  
  export function getConfiguration() {
    if (process.env.NODE_ENV === 'production') {
      return serverVars;
    }
  
    return localVars;
  }
